function getOrderOnlineLink(title) {
  const linkMap = {
    "p-prairie-donair-airdrie": "https://prairiedonair.gpr.globalpaymentsinc.ca/",
    "p-prairie-donair-deer-ridge": "https://prairiedonair.gpr.globalpaymentsinc.ca/",
    "p-prairie-donair-medicine-hat": "N/A",
    "p-prairie-donair-red-deer": "https://prairiedonair.gpr.globalpaymentsinc.ca/",
    "p-prairie-donair-neepawa": "https://prairiedonair.gpr.globalpaymentsinc.ca/",
    "p-prairie-donair-portage-la-prairie": "https://prairiedonair.gpr.globalpaymentsinc.ca/",
    "p-prairie-donair-sault-ste-marie": "N/A",
    "p-prairie-donair-assiniboia": "https://prairiedonair.gpr.globalpaymentsinc.ca/",
    "p-prairie-donair-regina-hamilton": "https://prairiedonair.gpr.globalpaymentsinc.ca/",
    "p-prairie-donair-regina-chuka": "https://prairiedonair.gpr.globalpaymentsinc.ca/",
    "p-prairie-donair-regina-dewdney": "N/A",
    "p-prairie-donair-saskatoon-idylwyld": "https://prairiedonair.gpr.globalpaymentsinc.ca/",
    "p-prairie-donair-saskatoon-2nd-Ave": "https://prairiedonair.gpr.globalpaymentsinc.ca/",
    "p-prairie-donair-swift-current": "https://prairiedonair.gpr.globalpaymentsinc.ca/",
    "p-prairie-donair-yorkton": "https://prairiedonair.gpr.globalpaymentsinc.ca/",
    "p-prairie-donair-regina-rochdale": "https://prairiedonair.gpr.globalpaymentsinc.ca/"
  };
  
  return linkMap[title] || `https://orders.iorders.online/${title}`;
}

// ... rest of the code remains unchanged
const storeDetails = {
  status: "success",
  data: {
    restaurants: [
      //2. Prairie Donair Crowfoot Calgary
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Crowfoot Calgary",
          title: "p-prairie-donair-crowfoot",
          address: "20 Crowfoot Crescent NW #820, Calgary, AB T3G 2P6",
          phone: "+1 (403) 452-0452",
          available_days: [
            {id: 1746, day: 'SUNDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1752, day: 'SATURDAY', opening_time: '00:00:10', closing_time: '01:00:00', order_mode: 1},
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1752, day: 'SUNDAY', opening_time: '00:00:10', closing_time: '01:00:00', order_mode: 1},
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "51.1251555164738",
          lng: "-114.1983159555949",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
       //4. Prairie Donair Chappelle Commons Edmonton
       {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Chappelle Commons Edmonton",
          title: "p-prairie-donair-chappelle-commons",
          address: "14121 28 Ave SW, Edmonton, AB T6W 0C3",
          phone: "+1 (780) 977-8328",
          available_days: [
            {id: 1746, day: 'SUNDAY', opening_time: '12:00:00', closing_time: '20:30:00', order_mode: 1},
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '20:00:00', order_mode: 1},
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '20:30:00', order_mode: 1},
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1750, day: 'THURSDAY', opening_time: '11:30:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1751, day: 'FRIDAY', opening_time: '11:30:00', closing_time: '22:00:00', order_mode: 1},
            {id: 1752, day: 'SATURDAY', opening_time: '11:30:00', closing_time: '21:00:00', order_mode: 1}
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "53.40964564113286",
          lng: "-113.56611488417334",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      //5. Prairie Donair Hampton Terrace Edmonton
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Hampton Terrace Edmonton",
          title: "p-prairie-donair-hampton-terrace",
          address: "20106 Lessard Rd NW, Edmonton, AB T6M 0K4",
          phone: "+1 (780) 752-9992",
          available_days: [
            {id: 1746, day: 'SUNDAY', opening_time: '12:00:00', closing_time: '20:30:00', order_mode: 1},
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1}
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "53.4778196086981",
          lng: "-113.67372939500545",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      //6. Prairie Donair Whyte Ave Edmonton
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Whyte Ave Edmonton",
          title: "p-prairie-donair-whyte-ave",
          address: "10744 82 Ave NW, Edmonton, AB T6E 2A8",
          phone: "+1 (587) 778-0336",
          available_days: [
            // Sunday
            {id: 1746, day: 'SUNDAY', opening_time: '12:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1746, day: 'MONDAY', opening_time: '00:00:10', closing_time: '01:00:00', order_mode: 1},
            // Monday
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            // Tuesday
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1748, day: 'WEDNESDAY', opening_time: '00:00:10', closing_time: '00:30:00', order_mode: 1},
            // Wednesday
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1749, day: 'THURSDAY', opening_time: '00:00:10', closing_time: '00:30:00', order_mode: 1},
            // Thursday
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1750, day: 'FRIDAY', opening_time: '00:00:10', closing_time: '00:30:00', order_mode: 1},
            // Friday
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1751, day: 'SATURDAY', opening_time: '00:00:10', closing_time: '04:00:00', order_mode: 1},
            // Saturday
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1752, day: 'SUNDAY', opening_time: '00:00:10', closing_time: '04:00:00', order_mode: 1},
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "53.518319357786574",
          lng: "-113.50805053321416",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      //8. Prairie Donair Olds Alberta
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Olds Alberta",
          title: "p-prairie-donair-olds",
          address: "4530 49 Ave Unit #4, Olds, AB T4H 1A4",
          phone: "+1 (403) 791-1010",
          available_days: [
            {id: 1746, day: 'SUNDAY', opening_time: '11:00:00', closing_time: '20:00:00', order_mode: 1},
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "51.7952718",
          lng: "-114.1025092",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      //12. Prairie Donair Steinbach Manitoba
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Steinbach Manitoba",
          title: "p-prairie-donair-steinbach",
          address: "178 MB-12 Unit 32C, Steinbach, MB R5G 1T7",
          phone: "+1 (204) 326-6151",
          available_days: [
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1746, day: 'SUNDAY', opening_time: '11:00:00', closing_time: '20:00:00', order_mode: 1},
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '20:00:00', order_mode: 1},
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "49.54701926411078",
          lng: "-96.69128728002605",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      //13. Prairie Donair Dominion Center Winnipeg Manitoba
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Dominion Center Winnipeg Manitoba",
          title: "p-prairie-donair-winnipeg-dominion-center",
          address: "63 Goulet St, Winnipeg, MB R2H 0G1",
          phone: "+1 (204) 231-1102",
          available_days: [
            // Sunday
            {id: 1746, day: 'SUNDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Monday
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Tuesday
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            // Wednesday
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            // Thursday
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1750, day: 'FRIDAY', opening_time: '00:00:10', closing_time: '02:00:00', order_mode: 1},
            // Friday
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1751, day: 'SATURDAY', opening_time: '00:00:10', closing_time: '02:00:00', order_mode: 1},
            // Saturday
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1752, day: 'SUNDAY', opening_time: '00:00:10', closing_time: '02:00:00', order_mode: 1},
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "49.882340",
          lng: "-97.125640",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      //14. Prairie Donair Bridgwater Winnipeg Manitoba
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Bridgwater Winnipeg Manitoba",
          title: "p-prairie-donair-winnipeg-bridgwater",
          address: "345 North Town Road #9, Winnipeg, MB R3Y 0Y4",
          phone: "+1 (204) 888-9550",
          available_days: [
            // Sunday
            {id: 1746, day: 'SUNDAY', opening_time: '10:00:00', closing_time: '24:00:00', order_mode: 1},
            // Monday
            {id: 1747, day: 'MONDAY', opening_time: '10:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1747, day: 'TUESDAY', opening_time: '00:00:10', closing_time: '02:00:00', order_mode: 1},
            // Tuesday
            {id: 1748, day: 'TUESDAY', opening_time: '10:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1748, day: 'WEDNESDAY', opening_time: '00:00:10', closing_time: '02:00:00', order_mode: 1},
            // Wednesday
            {id: 1749, day: 'WEDNESDAY', opening_time: '10:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1749, day: 'THURSDAY', opening_time: '00:00:10', closing_time: '02:00:00', order_mode: 1},
            // Thursday
            {id: 1750, day: 'THURSDAY', opening_time: '10:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1750, day: 'FRIDAY', opening_time: '00:00:10', closing_time: '02:00:00', order_mode: 1},
            // Friday
            {id: 1751, day: 'FRIDAY', opening_time: '10:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1751, day: 'SATURDAY', opening_time: '00:00:10', closing_time: '02:00:00', order_mode: 1},
            // Saturday
            {id: 1752, day: 'SATURDAY', opening_time: '10:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1752, day: 'SUNDAY', opening_time: '00:00:10', closing_time: '02:00:00', order_mode: 1},
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "49.79901588750163",
          lng: "-97.1959143903297",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      //15. Prairie Donair Main Street Winnipeg Manitoba
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Main Street Winnipeg Manitoba",
          title: "p-prairie-donair-winnipeg-main-street",
          address: "2405 Main St unit 600, Winnipeg, MB R2V 4T6",
          phone: "+1 (204) 334-5040",
          available_days: [
            // Sunday
            {id: 1746, day: 'SUNDAY', opening_time: '10:00:00', closing_time: '24:00:00', order_mode: 1},
            // Monday
            {id: 1747, day: 'MONDAY', opening_time: '10:00:00', closing_time: '24:00:00', order_mode: 1},
            // Tuesday
            {id: 1748, day: 'TUESDAY', opening_time: '10:00:00', closing_time: '24:00:00', order_mode: 1},
            // Wednesday
            {id: 1749, day: 'WEDNESDAY', opening_time: '10:00:00', closing_time: '24:00:00', order_mode: 1},
            // Thursday
            {id: 1750, day: 'THURSDAY', opening_time: '10:00:00', closing_time: '24:00:00', order_mode: 1},
            // Friday
            {id: 1751, day: 'FRIDAY', opening_time: '10:00:00', closing_time: '24:00:00', order_mode: 1},
            // Saturday
            {id: 1752, day: 'SATURDAY', opening_time: '10:00:00', closing_time: '24:00:00', order_mode: 1},
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "49.95426875133155",
          lng: "-97.0967086805698",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      //16. Prairie Donair St Vital Square Winnipeg Manitoba
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair St Vital Square Winnipeg Manitoba",
          title: "p-prairie-donair-winnipeg-st-vital-square",
          address: "835 Dakota St unit 1, Winnipeg, MB R2M 4Z2",
          phone: "+1 (204) 888-4082",
          available_days: [
            // Sunday
            {id: 1746, day: 'SUNDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            // Monday
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            // Tuesday
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            // Wednesday
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            // Thursday
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            // Friday
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            // Saturday
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "49.83010792491787",
          lng: "-97.10604291840798",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      //19. Prairie Donair Emerald Park Saskatchewan
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Emerald Park Saskatchewan",
          title: "p-prairie-donair-emerald-park",
          address: "22 Great Plains Rd, Emerald Park, SK S4L 1B6",
          phone: "+1 (306) 559-0550",
          available_days: [
            // Sunday
            {id: 1746, day: 'SUNDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Monday
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Tuesday
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Wednesday
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Thursday
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Friday
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Saturday
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: true,
          lat: "50.446652982883",
          lng: "-104.40719585035615",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      //21. Prairie Donair Moose Jaw
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Moose Jaw",
          title: "p-prairie-donair-moose-jaw",
          address: "930 Main St N, Moose Jaw, SK S6H 3K7",
          phone: "+1 (306) 983-1234",
          available_days: [
            // Sunday
            {id: 1746, day: 'SUNDAY', opening_time: '10:30:00', closing_time: '22:00:00', order_mode: 1},
            // Monday
            {id: 1747, day: 'MONDAY', opening_time: '10:30:00', closing_time: '22:00:00', order_mode: 1},
            // Tuesday
            {id: 1748, day: 'TUESDAY', opening_time: '10:30:00', closing_time: '22:00:00', order_mode: 1},
            // Wednesday
            {id: 1749, day: 'WEDNESDAY', opening_time: '10:30:00', closing_time: '22:00:00', order_mode: 1},
            // Thursday
            {id: 1750, day: 'THURSDAY', opening_time: '10:30:00', closing_time: '22:00:00', order_mode: 1},
            // Friday
            {id: 1751, day: 'FRIDAY', opening_time: '10:30:00', closing_time: '22:00:00', order_mode: 1},
            // Saturday
            {id: 1752, day: 'SATURDAY', opening_time: '10:30:00', closing_time: '22:00:00', order_mode: 1},
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "50.398462883063374",
          lng: "-105.53521261008069",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      //23. Prairie Donair Quance Regina
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Quance Regina",
          title: "p-prairie-donair-regina-quance",
          address: "2518 E Quance St, Regina, SK S4V 2X5",
          phone: "+13067897555",
          available_days: [
            // Sunday
            {id: 1751, day: 'SUNDAY', opening_time: '00:00:10', closing_time: '01:00:00', order_mode: 1},
            {id: 1746, day: 'SUNDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            // Monday
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            // Tuesday
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            // Wednesday
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            // Thursday
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            // Friday
            {id: 1751, day: 'FRIDAY', opening_time: '00:00:10', closing_time: '01:00:00', order_mode: 1},
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            // Saturday
            {id: 1751, day: 'SATURDAY', opening_time: '00:00:10', closing_time: '01:00:00', order_mode: 1},
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "50.44415240658435",
          lng: "-104.54776820447998",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      //26. Prairie Donair Rochdale Regina
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Rochdale Regina",
          title: "p-prairie-donair-regina-rochdale",
          address: "4115 Rochdale Blvd, Regina, SK S4X 4P7",
          phone: "+13065596040",
          available_days: [
            // Sunday
            {id: 1746, day: 'SUNDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            // Monday
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            // Tuesday
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            // Wednesday
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            // Thursday
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            // Friday
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            // Saturday
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "50.4964073488754",
          lng: "-104.64028189396802",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      //27. Prairie Donair - Albert St Regina
      {
        profile: {
          id: 123, 
          restaurant_name: "Prairie Donair Albert St Regina",
          title: "p-prairiedonair-albert-regina",
          address: "4160 Albert St, Regina, SK S4S 3R8, Canada",
          phone: "+1 306-559-2950",
          available_days: [
            {id: 1746, day: 'SUNDAY', opening_time: '10:31:00', closing_time: '21:55:00', order_mode: 1},
            {id: 1747, day: 'MONDAY', opening_time: '10:31:00', closing_time: '22:55:00', order_mode: 1},
            {id: 1748, day: 'TUESDAY', opening_time: '10:31:00', closing_time: '22:55:00', order_mode: 1},
            {id: 1749, day: 'WEDNESDAY', opening_time: '10:31:00', closing_time: '22:55:00', order_mode: 1},
            {id: 1750, day: 'THURSDAY', opening_time: '10:31:00', closing_time: '22:55:00', order_mode: 1},
            {id: 1751, day: 'FRIDAY', opening_time: '10:31:00', closing_time: '22:55:00', order_mode: 1},
            {id: 1752, day: 'SATURDAY', opening_time: '10:31:00', closing_time: '22:55:00', order_mode: 1}
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "50.4125721",
          lng: "-104.6200234",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      //31. Prairie Donair Stonebridge Saskatoon
      {
        profile: {
          id: 123, 
          restaurant_name: "Prairie Donair Stonebridge Saskatoon",
          title: "p-prairie-donair-saskatoon-stonebridge",
          address: "3210 Preston Ave S Unit #50, Saskatoon, SK S7T 1C9",
          phone: "+13069865382",
          available_days: [
            // Sunday
            {id: 1746, day: 'SUNDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Monday
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Tuesday
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Wednesday
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Thursday
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Friday
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Saturday
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1}
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "52.08492186077296",
          lng: "-106.62347657230369",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      //30. Prairie Donair 51st Saskatoon
      {
        profile: {
          id: 123, 
          restaurant_name: "Prairie Donair 51st Saskatoon",
          title: "p-prairie-donair-saskatoon-51st",
          address: "801 51st St #100, Saskatoon, SK S7K 5S2",
          phone: "+13069865249",
          available_days: [
            // Sunday
            {id: 1746, day: 'SUNDAY', opening_time: '11:00:00', closing_time: '19:00:00', order_mode: 1},
            // Monday
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '19:00:00', order_mode: 1},
            // Tuesday
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '19:00:00', order_mode: 1},
            // Wednesday
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '19:00:00', order_mode: 1},
            // Thursday
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '19:00:00', order_mode: 1},
            // Friday
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '19:00:00', order_mode: 1},
            // Saturday
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '19:00:00', order_mode: 1}
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "52.17258845009039",
          lng: "-106.65496684688263",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      //20. Prairie Donair Estevan
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Estevan",
          title: "p-prairie-donair-estevan",
          address: "421A Kensington Ave, Estevan, SK S4A 0V2",
          phone: "+1 (306) 634-4466",
          available_days: [
            // Sunday
            {id: 1746, day: 'SUNDAY', opening_time: '11:00:00', closing_time: '20:00:00', order_mode: 1},
            // Monday
            {id: 1747, day: 'MONDAY', opening_time: '10:00:00', closing_time: '21:00:00', order_mode: 1},
            // Tuesday
            {id: 1748, day: 'TUESDAY', opening_time: '10:00:00', closing_time: '21:00:00', order_mode: 1},
            // Wednesday
            {id: 1749, day: 'WEDNESDAY', opening_time: '10:00:00', closing_time: '21:00:00', order_mode: 1},
            // Thursday
            {id: 1750, day: 'THURSDAY', opening_time: '10:00:00', closing_time: '21:00:00', order_mode: 1},
            // Friday
            {id: 1751, day: 'FRIDAY', opening_time: '10:00:00', closing_time: '21:00:00', order_mode: 1},
            // Saturday
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "49.14533580250338",
          lng: "-102.96807160787257",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      
      //28. Prairie Donair Cavendish Regina
      {
        profile: {
          id: 123, 
          restaurant_name: "Prairie Donair Cavendish Regina",
          title: "p-prairie-donair-cavendish-st-regina",
          address: "90 Cavendish St, Regina, SK S4N 5G7",
          phone: "+1 306-559-2835",
          available_days: [
            // Sunday
            {id: 1746, day: 'SUNDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Monday
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '22:30:00', order_mode: 1},
            // Tuesday
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '22:30:00', order_mode: 1},
            // Wednesday
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '22:30:00', order_mode: 1},
            // Thursday
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '22:30:00', order_mode: 1},
            // Friday
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1751, day: 'SATURDAY', opening_time: '00:00:10', closing_time: '01:00:00', order_mode: 1},
            // Saturday
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            {id: 1752, day: 'SUNDAY', opening_time: '00:00:10', closing_time: '01:00:00', order_mode: 1}
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "50.45439262902993",
          lng: "-104.5575416451113",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },








      //1. Prairie Donair Airdrie Alberta
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Airdrie Alberta",
          title: "p-prairie-donair-airdrie",
          address: "705 Main St SW Unit 1000, Airdrie, AB T4B 3M2",
          phone: "+1 (403) 980-6060",
          available_days: [
            {id: 1746, day: 'SUNDAY', opening_time: '11:00:00', closing_time: '20:00:00', order_mode: 1},
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1}
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "51.28658",
          lng: "-114.014433",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      
      //3. Prairie Donair Deer Ridge Calgary
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Deer Ridge Calgary",
          title: "p-prairie-donair-deer-ridge",
          address: "14919 Deer Ridge Dr SE Unit# 111, Calgary, AB T2J 7C4",
          phone: "+1 (587) 864-4468",
          available_days: [
            {id: 1746, day: 'SUNDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1}
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "50.9303192",
          lng: "-114.024974",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
     
      
      
      //7. Prairie Donair Medicine Hat
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Medicine Hat",
          title: "p-prairie-donair-medicine-hat",
          address: "2410 Division Ave N #84, Medicine Hat, AB T1A 0C3",
          phone: "+1 (587) 289-4040",
          available_days: [
            // Sunday
            {id: 1746, day: 'SUNDAY', opening_time: '12:30:00', closing_time: '19:30:00', order_mode: 1},
            // Monday
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '15:00:00', order_mode: 1},
            {id: 1748, day: 'MONDAY', opening_time: '15:30:00', closing_time: '20:30:00', order_mode: 1},
            // Tuesday
            {id: 1749, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '15:00:00', order_mode: 1},
            {id: 1750, day: 'TUESDAY', opening_time: '15:30:00', closing_time: '20:30:00', order_mode: 1},
            // Wednesday
            {id: 1751, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '15:00:00', order_mode: 1},
            {id: 1752, day: 'WEDNESDAY', opening_time: '15:30:00', closing_time: '20:30:00', order_mode: 1},
            // Thursday
            {id: 1753, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '15:00:00', order_mode: 1},
            {id: 1754, day: 'THURSDAY', opening_time: '15:30:00', closing_time: '20:30:00', order_mode: 1},
            // Friday
            {id: 1755, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '15:00:00', order_mode: 1},
            {id: 1756, day: 'FRIDAY', opening_time: '15:30:00', closing_time: '21:00:00', order_mode: 1},
            // Saturday
            {id: 1757, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '20:30:00', order_mode: 1},
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "50.0626571",
          lng: "-110.6904374",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      
      //9. Prairie Donair Red Deer Alberta
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Red Deer Alberta",
          title: "p-prairie-donair-red-deer",
          address: "179 Leva Ave #106B, Red Deer County, AB T4E 1B9",
          phone: "+1 (403) 986-8583",
          available_days: [
            {id: 1751, day: 'FRIDAY', opening_time: '12:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1752, day: 'SATURDAY', opening_time: '12:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1746, day: 'SUNDAY', opening_time: '12:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1747, day: 'MONDAY', opening_time: '12:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1748, day: 'TUESDAY', opening_time: '12:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1749, day: 'WEDNESDAY', opening_time: '12:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1750, day: 'THURSDAY', opening_time: '12:00:00', closing_time: '21:00:00', order_mode: 1},
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "52.2172564",
          lng: "-113.8149164",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      //10. Prairie Donair Neepawa Manitoba
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Neepawa Manitoba",
          title: "p-prairie-donair-neepawa",
          address: "160 Main St W, Neepawa, MB R0J 1H0",
          phone: "+1 (204) 704-7040",
          available_days: [
            {id: 1746, day: 'SUNDAY', opening_time: '10:00:00', closing_time: '20:45:00', order_mode: 1},
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "50.224289",
          lng: "-99.489822",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      //11. Prairie Donair Portage La Prairie Manitoba
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Portage La Prairie Manitoba",
          title: "p-prairie-donair-portage-la-prairie",
          address: "306 Saskatchewan Ave E, Portage la Prairie, MB R1N 0K8",
          phone: "+1 (204) 239-0050",
          available_days: [
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1746, day: 'SUNDAY', opening_time: '11:00:00', closing_time: '20:00:00', order_mode: 1},
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "49.972873",
          lng: "-98.284965",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      
      //17. Prairie Donair Sault Ste Marie Ontario
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Sault Ste Marie Ontario",
          title: "p-prairie-donair-sault-ste-marie",
          address: "129 Second Line W Unit-1, Sault Ste. Marie, ON P6C 2J1",
          phone: "+1 (705) 256-7267",
          available_days: [
            // Sunday
            {id: 1746, day: 'SUNDAY', opening_time: '13:00:00', closing_time: '24:00:00', order_mode: 1},
            // Monday
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            // Tuesday
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            // Wednesday
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            // Thursday
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            // Friday
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '24:00:00', order_mode: 1},
            // Saturday
            {id: 1752, day: 'SATURDAY', opening_time: '12:00:00', closing_time: '24:00:00', order_mode: 1},
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "46.537434",
          lng: "-84.353404",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      //18. Prairie Donair Assiniboia, Saskatchewan
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Assiniboia, Saskatchewan",
          title: "p-prairie-donair-assiniboia",
          address: "102 1 Ave W, Assiniboia, SK S0H 0B0",
          phone: "+1 (306) 642-5011",
          available_days: [
            // Sunday
            {id: 1746, day: 'SUNDAY', opening_time: '11:00:00', closing_time: '20:00:00', order_mode: 1},
            // Monday
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Tuesday
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Wednesday
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Thursday
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Friday
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Saturday
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "49.631844",
          lng: "-105.993043",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      
      
      
      //22. Prairie Donair Hamilton St. Regina
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Hamilton Regina",
          title: "p-prairie-donair-regina-hamilton",
          address: "1914 Hamilton St Unit: 120, Regina, SK S4P 3N6",
          phone: "+13065592772",
          available_days: [
            // Sunday
            {id: 1746, day: 'SUNDAY', opening_time: '11:00:00', closing_time: '18:00:00', order_mode: 1},
            // Monday
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            // Tuesday
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            // Wednesday
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            // Thursday
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            // Friday
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            // Saturday
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '18:00:00', order_mode: 1},
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "50.448406017201634",
          lng: "-104.60920712182211",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      
      //24. Prairie Donair Chuka Regina
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Chuka Regina",
          title: "p-prairie-donair-regina-chuka",
          address: "3763 Chuka Blvd #1, Regina, SK S4V 3P7",
          phone: "+13065596363",
          available_days: [
            // Sunday
            {id: 1746, day: 'SUNDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            // Monday
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            // Tuesday
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            // Wednesday
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            // Thursday
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            // Friday
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            // Saturday
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "50.41695388075705",
          lng: "-104.51301556230383",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      //25. Prairie Donair Dewdney Regina
      {
        profile: {
          id: 123,
          restaurant_name: "Prairie Donair Dewdney Regina",
          title: "p-prairie-donair-regina-dewdney",
          address: "3934 Dewdney Ave, Regina, SK S4T 1A1",
          phone: "+13065592186",
          available_days: [
            // Sunday
            {id: 1746, day: 'SUNDAY', opening_time: '12:00:00', closing_time: '21:00:00', order_mode: 1},
            // Monday
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            // Tuesday
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            // Wednesday
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            // Thursday
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            // Friday
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
            // Saturday
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '23:00:00', order_mode: 1},
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "50.455423790581506",
          lng: "-104.63723639963473",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      
      //29. Prairie Donair Idylwyld Saskatoon
      {
        profile: {
          id: 123, 
          restaurant_name: "Prairie Donair Idylwyld Saskatoon",
          title: "p-prairie-donair-saskatoon-idylwyld",
          address: "1421 Idylwyld Dr N Unit 50, Saskatoon, SK S7L 1A7",
          phone: "+13069865198",
          available_days: [
            // Sunday
            {id: 1746, day: 'SUNDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Monday
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            // Tuesday
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            // Wednesday
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            // Thursday
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            // Friday
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            // Saturday
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1}
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "52.14780422444799",
          lng: "-106.67022471804827",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      
      
      //32. Prairie Donair Downtown Saskatoon
      {
        profile: {
          id: 123, 
          restaurant_name: "Prairie Donair Downtown Saskatoon",
          title: "p-prairie-donair-saskatoon-2nd-Ave",
          address: "402 2nd Ave N #110, Saskatoon, SK S7K 2C3",
          phone: "+13069865200",
          available_days: [
            // Sunday
            {id: 1746, day: 'SUNDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            // Monday
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            // Tuesday
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            // Wednesday
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            // Thursday
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            // Friday
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            // Saturday
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1}
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "52.13382865452092",
          lng: "-106.66166683413425",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      //33. Prairie Donair Swift Current
      {
        profile: {
          id: 123, 
          restaurant_name: "Prairie Donair Swift Current",
          title: "p-prairie-donair-swift-current",
          address: "701 Robert St E #4B, Swift Current, SK S9H 5G7",
          phone: "+13067733328",
          available_days: [
            // Sunday
            {id: 1746, day: 'SUNDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Monday
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            // Tuesday
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            // Wednesday
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            // Thursday
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            // Friday
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '22:00:00', order_mode: 1},
            // Saturday
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1}
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "50.29912047198267",
          lng: "-107.78911088374976",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      //34. Prairie Donair Yorkton
      {
        profile: {
          id: 123, 
          restaurant_name: "Prairie Donair Yorkton",
          title: "p-prairie-donair-yorkton",
          address: "146 Broadway St E Unit-6, Yorkton, SK S3N 3K4",
          phone: "+13067083388",
          available_days: [
            // Sunday
            {id: 1746, day: 'SUNDAY', opening_time: '11:00:00', closing_time: '20:00:00', order_mode: 1},
            // Monday
            {id: 1747, day: 'MONDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Tuesday
            {id: 1748, day: 'TUESDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Wednesday
            {id: 1749, day: 'WEDNESDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Thursday
            {id: 1750, day: 'THURSDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Friday
            {id: 1751, day: 'FRIDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1},
            // Saturday
            {id: 1752, day: 'SATURDAY', opening_time: '11:00:00', closing_time: '21:00:00', order_mode: 1}
          ],
          close_restaurent: false,
          disable_takeout_restaurent: false,
          disable_delivery_restaurent: false,
          lat: "51.21000841205506",
          lng: "-102.4537170611629",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      

      
    ]
  },
  message: "successful response"
};

export default storeDetails;
